import {Layout} from "components/layout"
import Container from "@/components/ui/container"
import React from "react"
import Link from "next/link"
import {Button} from "@/components/ui/button";

export default function Custom404() {
  return (
    <Layout>
      <Container className="py-10">
        <h1 className="mb-2 text-[128px] text-brand-mer-500 text-center mb-0">404</h1>
        <h1 className="mb-2 text-[40px] text-neutral-1000 text-center mb-0">Page non trouvé</h1>
        <div className="mt-8 flex justify-center gap-x-8">
          <Button className="justify-self-center mr-5" asChild>
            <Link href="/podcasts">Voir les podcast</Link>
          </Button>
          <Button className="justify-self-center" variant="secondary" asChild>
            <Link href="/articles">Voir les articles</Link>
          </Button>
        </div>
        <div className="mt-4 flex justify-center">
          <Link className="text-brand-mer-600" href="/">Retour à l&apos;accueil</Link>
        </div>
      </Container>
    </Layout>
  )
}
